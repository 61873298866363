import React, { useState, useEffect } from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import "./Debt.css";
import api from "../../api/tokenCode";
import { format } from "date-fns";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import DatePicker from "react-datepicker";
import { notifySuccess } from "../../constant/toast";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const checkboxes = [
  { id: 1, title: "Vehicle" },
  { id: 2, title: "Student Loan" },
  { id: 3, title: "Mortgage" },
  { id: 4, title: "Personal Loan" },
  { id: 5, title: "Credit Card" },
];

const InputField = ({ placeholder, register, error, type = "text", style }) => (
  <div className="input-field-container">
    <input
      type={type}
      className="input-field"
      onInput={(e) => {
        if (type === "text") {
          let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
          inputValue = inputValue.length > 1 ? inputValue.trimStart() : inputValue; // Trim leading spaces only if more than one character
          e.target.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
        } else if (type === "number") {
          e.target.value = e.target.value.replace(/[eE]/g, ""); // Prevent 'e' and 'E'
        }
      }}
      placeholder={placeholder}
      {...register}
      style={style}
    />


    {error && <p className="error-message">{error.message}</p>}
  </div>
);

const UpdateDebt = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const [debtData, setDebtData] = useState(null);
  const [savedDebt, setSavedDebt] = useState(null);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null); // State for selected checkbox
  const clientData = JSON.parse(localStorage.getItem("client"));
  const userId = clientData?.user_id || "";
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [open, setOpen] = useState(false);
  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, "MM/dd/yyyy"); // Format to MM/DD/YYYY
      setStartDate(formattedDate); // Set in local state
      setValue("startDate", formattedDate); // Set in form state
    }
  };

  useEffect(() => {
    const fetchDebtData = async () => {
      try {
        const response = await api.get(
          `/debt/getSingleDebtDetails/${userId}/${id}`
        );
        setDebtData(response.data.debtData);
        populateForm(response.data.debtData);
      } catch (error) {
        console.error("Error fetching debt data:", error);
      }
    };

    fetchDebtData();
  }, [id]);

  const populateForm = (data) => {
    if (!data || data.length === 0) {
      console.error("No data available to populate the form.");
      return;
    }

    const debt = data[0];

    // Set the values from the debt data to the form fields
    setValue("name", debt.name || "");
    setValue("loanAmount", debt.loanAmount || "");
    setValue("apr", debt.apr || "");
    setValue("currentPayment", debt.currentPayment || "");
    setValue("minimumPayment", debt.minimumPayment || "");
    if (debt.startDate) {
      const formattedDate = format(new Date(debt.startDate), "MM/dd/yyyy");
      setStartDate(formattedDate); // set local state
      setValue("startDate", formattedDate); // set form field
    }
    setValue("other", debt.other === "Yes");
    setValue("otherDescription", debt.otherStr !== "None" ? debt.otherStr : "");

    const selectedCheckbox = checkboxes.find(
      (checkbox) => checkbox.title === debt.type
    );
    if (selectedCheckbox) {
      setSelectedCheckbox(selectedCheckbox.id); // Set selected checkbox id
      setValue(`checkboxes.${selectedCheckbox.id}`, true);
    }
  };

  const handleCheckboxChange = (id) => {
    // Uncheck all checkboxes except the one with the given id
    checkboxes.forEach(({ id: checkboxId }) => {
      if (checkboxId !== id) {
        setValue(`checkboxes.${checkboxId}`, false);
      }
    });

    setSelectedCheckbox(id); // Update the selected checkbox id
    setValue(`checkboxes.${id}`, true);
  };

  const handleRowClick = () => {
    navigate(`/details/${clientData.id}`);
  };

  const onSubmit = async (formData) => {
    const selectedType =
      checkboxes.find(({ id }) => formData.checkboxes?.[id])?.title ||
      (formData.other ? "Other" : "");

    const updatedDebtData = [
      {
        otherStr: formData.other ? formData.otherDescription || "None" : "None",
        name: formData.name || "",
        loanAmount: formData.loanAmount || "",
        apr: formData.apr || "",
        currentPayment: formData.currentPayment || "",
        minimumPayment: formData.minimumPayment || "",
        startDate: startDate || "",
        type: selectedType,
      },
    ];

    try {
      const response = await api.put(`/debt/updateDebt/${id}`, {
        loan: updatedDebtData,
      });
      setSavedDebt(response.data);
      notifySuccess("Debt Updated successfully!");
      setTimeout(() => {
        navigate(`/details/${clientData.id}`);
      }, 2000);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSidenav data={savedDebt} />
      <Box
        component="main"
        className="debtPadding"
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          // overflowY: "auto",
        }}
      >
        <div className="main-container">
          <div>
            <h1 className="debt-name">UPDATE DEBT</h1>
            <button
              type="button"
              className="back-button"
              onClick={handleRowClick}
            >
              BACK
            </button>
          </div>
          <div className="container">
            <p className="">UPDATE LOAN</p>
          </div>
          <hr className="hr-tag" />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="checkbox-list">
              {checkboxes.map(({ id, title }) => (
                <label key={id} className="checkbox-item">
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    {...register(`checkboxes.${id}`)}
                    onChange={() => handleCheckboxChange(id)}
                  />
                  {title}
                </label>
              ))}
              <label className="checkbox-item">
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  {...register("other")}
                />
                Other:
                <Controller
                  name="otherDescription"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      onInput={(e) =>
                      (e.target.value = e.target.value.replace(
                        /[^A-Za-z]/g,
                        ""
                      ))
                      }
                      placeholder="Describe"
                      className="input-field"
                      {...field}
                    />
                  )}
                />
              </label>
            </div>

            <div className="input-container">
              {/* Lender: Text input */}
              <InputField
                placeholder="Lender"
                register={register("name", {
                  required: "* Lender is required",
                })}
                error={errors.name}
              />

              {/* Loan Amount: Numeric input, allows only numbers */}
              <InputField
                type="number" // Numeric input
                placeholder="Loan Amount"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                } // Ensure only digits
                register={register("loanAmount", {
                  required: "* Loan Amt is required",
                })}
                error={errors.loanAmount}
              />

              {/* APR %: Numeric input */}
              <InputField
                type="number" // Numeric input
                placeholder="APR %"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                } // Ensure only digits
                register={register("apr", {
                  required: "* APR is required",
                })}
                error={errors.apr}
              />

              {/* Current Payment: Numeric input */}
              <InputField
                type="number" // Numeric input
                placeholder="Current Pymt"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                } // Ensure only digits
                register={register("currentPayment", {
                  required: "* Curnt Pymt is required",
                })}
                error={errors.currentPayment}
              />

              {/* Minimum Payment: Numeric input */}
              <InputField
                type="number" // Numeric input
                placeholder="Min Pymt"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                } // Ensure only digits
                register={register("minimumPayment", {
                  required: "* Min Pymt is required",
                })}
                error={errors.minimumPayment}
              />

              {/* React DatePicker for Start Date */}
              <div className="input-field-container">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        open={open} // Controls DatePicker open state
                        value={startDate ? new Date(startDate) : null}
                        onChange={(date) => {
                          handleDateChange(date);
                          field.onChange(date); // Pass the selected date to the form field
                        }}
                        onOpen={() => setOpen(true)} // Opens DatePicker
                        onClose={() => setOpen(false)} // Closes DatePicker
                        renderInput={(params) => (
                          <TextField
                            inputRef={params.inputRef} // Attach the input ref
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true, // Prevent manual typing
                            }}
                            placeholder="Start Date"
                            InputLabelProps={{ shrink: false }} // Prevents label from shrinking
                            label={null} // Removes the label
                            onClick={() => setOpen(true)} // Opens DatePicker on input field click
                            sx={{
                              borderRadius: "0px",
                              width: "150px",
                              "& .MuiInputBase-root": {
                                borderRadius: "0px",
                                border: "1px solid #dddddd",
                                width: "145px",
                                fontSize: "15px",
                                cursor: "pointer",

                                "&:focus-within": {
                                  border: "1px solid #dddddd", // Makes border transparent when focused
                                },
                              },
                              "& .MuiInputBase-input": {
                                padding: "0px",
                                outline: "none", // Ensures no outline on input
                              },
                              "& .MuiOutlinedInput-input": {
                                padding: "1px 10px",
                                cursor: "pointer",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "none", // Removes the border on focus
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CalendarTodayIcon
                                    style={{ fontSize: 15, cursor: "pointer" }}
                                    onClick={() => setOpen(true)} // Opens DatePicker on icon click
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors.startDate && (
                    <p className="error-message">{errors.startDate.message}</p>
                  )}
                </LocalizationProvider>

                {errors.startDate && (
                  <p className="error-message">{errors.startDate.message}</p>
                )}
              </div>

              <div>
                <button type="submit" className="submit-button">
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default UpdateDebt;
