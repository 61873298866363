import React, { useState } from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import "./Coverage.css";
import { format } from "date-fns";
import api from "../../api/tokenCode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess } from "../../constant/toast";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"; // Import MUI DatePicker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Reusable InputField Component
const InputField = ({
  name,
  placeholder,
  className,
  field,
  error,
  type = "text",
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="input-field-container">
      {type === "date" ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            open={open}
            onOpen={() => setOpen(true)} // Opens DatePicker on input field click
            onClose={() => setOpen(false)} // Closes DatePicker
            value={field.value ? new Date(field.value) : null}
            onChange={(date) => {
              field.onChange(date ? format(date, "MM/dd/yyyy") : "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                InputLabelProps={{ shrink: false }} // Prevents label from shrinking
                label={null} // Removes the label
                inputProps={{
                  ...params.inputProps,
                  readOnly: true, // Prevents manual typing
                }}
                onClick={() => setOpen(true)} // Opens DatePicker on click
                sx={{
                  borderRadius: "0px",
                  width: "200px",
                  "& .MuiInputBase-root": {
                    borderRadius: "0px",
                    border: "1px solid #dddddd",
                    width: "200px",
                    fontSize: "15px",
                    cursor: "pointer",

                    "&:focus-within": {
                      border: "1px solid #dddddd", // Makes border transparent when focused
                    },
                  },
                  "& .MuiInputBase-input": {
                    padding: "0px",
                    outline: "none", // Ensures no outline on input
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "1px 10px",
                    cursor: "pointer",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Removes the border on focus
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayIcon
                        style={{ fontSize: 15, cursor: "pointer" }}
                        onClick={() => setOpen(true)} // Opens DatePicker on icon click
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      ) : (
        <input
          type={type}
          onInput={(e) => {
            if (type === "text") {
              let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
              inputValue = inputValue.length > 1 ? inputValue.trimStart() : inputValue; // Trim leading spaces if more than one character
              e.target.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1); // Capitalize the first letter
            } else if (type === "number") {
              e.target.value = e.target.value.replace(/[eE]/g, ""); // Prevent 'e' and 'E'
            }
          }}
          className={className}
          placeholder={placeholder}
          {...field}
        />

      )}
      {error && <p className="error-message">{error.message}</p>}
    </div>
  );
};

// Updated FormSection to pass 'type' dynamically
const FormSection = ({ form, onSubmit, fields, title }) => (
  <form onSubmit={form.handleSubmit(onSubmit)}>
    <div className="containers">
      <p className="text-with-underline">{title}</p>
    </div>
    <hr className="hr-tag" />
    <div className="input-container">
      {fields.map(({ name, placeholder, className, rules, type }) => (
        <Controller
          key={name}
          name={name}
          control={form.control}
          rules={rules}
          render={({ field }) => (
            <InputField
              name={name}
              placeholder={placeholder}
              className={className}
              field={field}
              type={type} // Pass type dynamically here
              error={form.formState.errors[name]}
            />
          )}
        />
      ))}
      <div className="button-div">
        <button type="submit" className="submit-button">
          ADD
        </button>
      </div>
    </div>
  </form>
);

const Coverage = () => {
  const termForm = useForm();
  const wholeLifeForm = useForm();
  // const finalExpenseForm = useForm();
  const [savedCoverage, setSavedCoverage] = useState(null);

  const [termDetails, setTermDetails] = useState([]);

  const [wholeLifeDetails, setWholeLifeDetails] = useState([]);

  // const [finalExpenseDetails, setFinalExpenseDetails] = useState([]);
  const navigate = useNavigate();
  const clientData = JSON.parse(localStorage.getItem("client"));
  const clientId = clientData?.user_id || "";

  const handleRowClick = () => {
    navigate(`/details/${clientData.id}`);
  };

  const handleSubmitTermCoverage = async (data) => {
    const processedData = {
      termCoverage: {
        carrier: data.termCarrier || "",
        deathBenefit: data.termDeathBenefit || "",
        startDate: data.termStartDate || "",
        length: data.termLengthYears || "",
      },
      clientId,
      coverageType: "term",
    };

    const updatedLoanDetails = [...termDetails, processedData];

    setTermDetails(updatedLoanDetails);

    try {
      const response = await api.post("/coverage/createTermCoverage", {
        processedData: updatedLoanDetails,
      });
      console.log(response);
      
      termForm.reset({
        termCarrier: "",
        termDeathBenefit: "",
        termStartDate: "",
        termLengthYears: "",
      });
      // setSavedCoverage(response.data);
      setSavedCoverage(null);
      // Set the savedCoverage state
      notifySuccess("Term Created successfully!");
      //  setTimeout(() => {
      //    navigate(`/details/${clientData.id}`);
      //  }, 3000);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  const handleSubmitWholeLifeCoverage = async (data) => {
    const processedData = {
      wholeLifeCoverage: {
        carrier: data.wholeLifeCarrier || "",
        year1: data.wholeLifeYear1 || "",
        year5: data.wholeLifeYear5 || "",
        year10: data.wholeLifeYear10 || "",
        year20: data.wholeLifeYear20 || "",
        year40: data.wholeLifeYear40 || "",
        startDate: data.wholeLifeStartDate || "",
      },
      clientId,
      coverageType: "wholeLifeCoverage",
    };
    const updatedLoanDetails = [...wholeLifeDetails, processedData];

    // setWholeLifeDetails(updatedLoanDetails);
    setWholeLifeDetails([]);
    try {
      const response = await api.post("/coverage/createWholeLifeCoverage", {
        processedData: updatedLoanDetails,
      });
      console.log(response);
      

      // setSavedCoverage(response.data); // Set the savedCoverage state
      setSavedCoverage(null);

      notifySuccess("Whole Life Created successfully!");
      wholeLifeForm.reset({
        wholeLifeCarrier: "",
        wholeLifeYear1: "",
        wholeLifeYear5: "",
        wholeLifeYear10: "",
        wholeLifeYear20: "",
        wholeLifeYear40: "",
        wholeLifeStartDate: "",
      });

      //  setTimeout(() => {
      //    navigate(`/details/${clientData.id}`);
      //  }, 3000);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  // const handleSubmitFinalExpenseCoverage = async (data) => {
  //   const processedData = {
  //     finalExpenseCoverage: {
  //       carrier: data.finalExpenseCarrier || "",
  //       deathBenefit: data.finalExpenseDeathBenefit || "",
  //       startDate: data.finalExpenseStartDate || "",
  //     },
  //     clientId,
  //     coverageType: "final",
  //   };

  //   const updatedLoanDetails = [...finalExpenseDetails, processedData];

  //   setFinalExpenseDetails([]);

  //   try {
  //     const response = await api.post("/coverage/createFinalExpenseCoverage", {
  //       processedData: updatedLoanDetails,
  //     });

  //     // setSavedCoverage(response.data); // Set the savedCoverage state

  //     finalExpenseForm.reset(); // Clear the form data
  //     notifySuccess("Final Expenses Created successfully!");
  //     finalExpenseForm.reset({
  //       finalExpenseCarrier: "",
  //       finalExpenseDeathBenefit: "",
  //       finalExpenseStartDate: "",
  //     });
  //     // setTimeout(() => {
  //     //   navigate(`/details/${clientData.id}`);
  //     // }, 3000);
  //   } catch (error) {
  //     console.error("Form submission error:", error);
  //   }
  // };

  const termFields = [
    {
      name: "termCarrier",
      placeholder: "Carrier",
      className: "input-field-sub-1",
      rules: { required: "* Carrier is required" },
    },
    {
      name: "termDeathBenefit",
      placeholder: "Death Benefit",
      type: "number",
      className: "input-field-sub-2",
      rules: { required: "* Death Benefit is required" },
    },
    {
      name: "termStartDate",
      placeholder: "Start Date",
      type: "date",
      className: "input-field-sub-2",
      rules: { required: "* Start Date is required" },
    },
    {
      name: "termLengthYears",
      placeholder: "Length (years)",
      type: "number",
      className: "input-field-sub-2",
      rules: { required: "* Length (years) is required" },
    },
  ];

  const wholeLifeFields = [
    {
      name: "wholeLifeCarrier",
      placeholder: "Carrier",
      className: "input-field-sub-1",
      rules: { required: "* Carrier is required" },
    },
    {
      name: "wholeLifeYear1",
      placeholder: "Year 1",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 1 is required" },
    },
    {
      name: "wholeLifeYear5",
      placeholder: "Year 5",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 5 is required" },
    },
    {
      name: "wholeLifeYear10",
      placeholder: "Year 10",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 10 is required" },
    },
    {
      name: "wholeLifeYear20",
      placeholder: "Year 20",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 20 is required" },
    },
    {
      name: "wholeLifeYear40",
      placeholder: "Year 40",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 40 is required" },
    },
    {
      name: "wholeLifeStartDate",
      placeholder: "Start Date",
      type: "date",
      className: "input-field-sub-2",
      rules: { required: "* Start Date is required" },
    },
  ];
  
  // const finalExpenseFields = [
  //   {
  //     name: "finalExpenseCarrier",
  //     placeholder: "Carrier",
  //     className: "input-field-sub-1",
  //     rules: { required: "* Carrier is required" },
  //   },
  //   {
  //     name: "finalExpenseDeathBenefit",
  //     placeholder: "Death Benefit",
  //     type: "number",
  //     className: "input-field-sub-2",
  //     rules: { required: "* Death Benefit is required" },
  //   },
  //   {
  //     name: "finalExpenseStartDate",
  //     placeholder: "Start Date",
  //     type: "date",
  //     className: "input-field-sub-2",
  //     rules: { required: "* Start Date is required" },
  //   },
  // ];

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSidenav />
      <Box
        component="main"
        className="coveragePadding"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          overflowY: "auto",
        }}
      >
        <div>
          <h1 className="debt-name">COVERAGE</h1>
          <button
            type="button"
            className="back-button"
            onClick={handleRowClick}
          >
            BACK
          </button>
        </div>
        <FormSection
          form={termForm}
          onSubmit={handleSubmitTermCoverage}
          fields={termFields}
          title="TERM"
        />
        <FormSection
          form={wholeLifeForm}
          onSubmit={handleSubmitWholeLifeCoverage}
          fields={wholeLifeFields}
          title="PERMANENT"
        />
        {/* <FormSection
          form={finalExpenseForm}
          onSubmit={handleSubmitFinalExpenseCoverage}
          fields={finalExpenseFields}
          title="FINAL EXPENSE"
        /> */}
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Coverage;
