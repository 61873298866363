import React,{useState,useEffect} from 'react';
import { Routes, Route, useLocation ,useNavigate} from 'react-router-dom';
import Dashboard from '../components/ClientList';
import NotFound from '../NotFound';
import Header from '../components/Header/Header';
import ClientDetail from '../components/ClientDetails';
import LoginPage from '../components/Login/LoginPage';
import DebtPage from '../components/Debt/Debt';
import Coverage from '../components/Coverage/Coverage';
// import IntakeForm from '../components/IntakeForm/FormInfo';
import IntakeForm from '../components/Intake';
import UpdateDebt from '../components/Debt/EditDebt';
import ForgotPasswordPage from '../components/ForgetPassword/ForgetPassword';
import Reviews from '../components/Reviews/Review';
import ResetPasswordPage from '../components/ResetPassword/ResetPassword';
import UpdateFormInfo from '../components/Intake';
import UpdateCoverage from '../components/Coverage/Editcoverage';
import ClientListDetails from '../components/ClientList';

function Main() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = () => {
      if (!localStorage.getItem("access_token")) {
        navigate("/");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Clean up event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  const location = useLocation();
  const [userEmail, setUserEmail] = useState(null);
  const shouldShowHeader = !['/','/create','/dashboard','/editformInfo/:id', '/details/:id', '/details/:id/debt', '/details/:id/coverage','/details/:id/debt/:id/:id','/details/:id/coverage/:id/:id','/forgot-password', '/reset-password/:token','/details/:id/reviews'].some(path => new RegExp(`^${path.replace(/:[^\s/]+/g, '[^/]+')}$`).test(location.pathname));
  
  const handleLogin = (email) => {
    setUserEmail(email);
  }; 


  return (  
    <div className="App">
      {/* {shouldShowHeader && <Header userEmail={userEmail} />} */}
      <Routes>
      <Route path="/" element={<LoginPage onLogin={handleLogin}/>} />
        <Route path="/dashboard" element={<ClientListDetails />} />
        <Route path="/details/:id" element={<ClientDetail />} />
        <Route path="/details/:id/debt" element={<DebtPage />} />
        <Route path="/create" element={<IntakeForm />} />
        <Route path="/details/:id/coverage" element={<Coverage />} />
        <Route path="/details/:id/debt/:id/:id" element={<UpdateDebt />} />
        <Route path="/details/:id/coverage/:id/:id" element={<UpdateCoverage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} /> 
        <Route path="/details/:id/reviews" element={<Reviews />} />
        <Route path="/editformInfo/:id" element={<UpdateFormInfo />} />
        <Route path="/details/:id" element={<ClientDetail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default Main;


