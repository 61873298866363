import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FamilyData from "./FamilyData";
import AssetsIncome from "./AssetIncome";
import FutureIncome from "./FutureIncome";
import FuturePlanningForm from "./FuturePlan";
import ClientInfo from "./ClientInfo";
import "./FormDesign.css";
import api from '../../api/tokenCode';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess, notifyError } from '../../constant/toast';

const UpdateFormInfo = () => {
  const { id } = useParams(); 
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    clientInfo: {},
    assetsIncome: {},
    futureIncome: {},
    futurePlanning: {},
    assetsData: {},
  });
  const clientData = JSON.parse(localStorage.getItem('client'));

const clientId = clientData?.user_id || '';
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/details/${clientData.id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/form/getSingleClientDetails/${id}`);
        const data = response.data;

        setFormData({
          clientInfo: data.clientInfo || {},
          assetsIncome: data.assetsIncome || {},
          futureIncome: data.futureIncome || {},
          futurePlanning: data.futurePlaning || {},
          assetsData: data.assestData || {},
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
        notifyError('Error loading data!');
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleSubmitForm = async (formData) => {
    try {
      const response = await api.put(`/form/updateForm/${id}`, formData);
      const updatedClientData = response.data;
      
      notifySuccess('Form Updated Successfully');
  
      localStorage.setItem('client', JSON.stringify(updatedClientData));
  
      setTimeout(() => {
        navigate(`/details/${updatedClientData.id}`);
      }, 2000);
  
    } catch (error) {
      console.error('Error:', error.response?.data?.message);
      notifyError(` ${error.response?.data?.message}`);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box className="familyDataContainer"  sx={{width:'fit-content', paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
      
      <Typography variant="h4" sx={{textAlign:'center',color:'#24527B',letterSpacing:'5px',fontFamily:'fangsong'}}>
        UPDATE INTAKE FORM
      </Typography>

      <button type="submit" className="back-buttonintake" onClick={handleRowClick}>Back</button>

      <Box sx={{ mt: 3 }}>
        <ClientInfo
          onDataChange={(data) => setValue('clientInfo', data)}
          initialData={formData.clientInfo}
        />
        <FamilyData
          onDataChange={(data) => setValue('assetsIncome', data)}
          initialData={formData.assetsIncome}
        />
        <AssetsIncome
          onDataChange={(data) => setValue('assetsData', data)}
          initialData={formData.assetsData}
        />
        <FutureIncome
          onDataChange={(data) => setValue('futureIncome', data)}
          initialData={formData.futureIncome}
        />
        <FuturePlanningForm
          onDataChange={(data) => setValue('futurePlanning', data)}
          initialData={formData.futurePlanning}
        />
      </Box>
      
      <Box sx={{ textAlign: "right", mt: 3 }}>
      <Button variant="outlined" sx={{marginRight: 2,borderColor: '#24527B',color: '#24527B', }} onClick={() =>{navigate(`/details/${clientData.id}`)}}>
        Cancel
      </Button>
        <Button variant="contained" sx={{ marginRight: 2,backgroundColor: "#24527B",'&:hover': {  backgroundColor: "#24527B"  }}} onClick={handleSubmit(handleSubmitForm)}>
          UPDATE
        </Button>

      </Box>

      {/* Add ToastContainer to the component */}
      <ToastContainer />
    </Box>
  );
};

export default UpdateFormInfo;
