import React, { useState } from "react";
import { Box } from "@mui/material";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import "./Review.css";
import api from "../../api/tokenCode";

const Reviews = () => {
  const today = new Date();
  const formattedDate = `${
    today.getMonth() + 1
  }/${today.getDate()}/${today.getFullYear()}`;

  const initialFormState = {
    questions: [
      "Any major life changes since last review?",
      "Any updates or changes to the current coverage?",
      "Has anything changed in your health?",
      "Are all family members adequately covered?",
      "Is it time to consider converting your term policy to permanent?",
      "Do you have an old 401K from a previous job?",
      "Are you happy with how your retirement accounts have been doing?",
      "Do you have any debts you’re trying to pay off?",
      "Are there any friends or neighbors who need my services?",
    ].map((question) => ({ question, answer: "", checked: false })),
    notes: "",
    id: 1,
  };

  const [forms, setForms] = useState([initialFormState]);

  const addNewForm = () => {
    const newFormId = forms.length + 1;
    setForms([
      ...forms,
      {
        ...initialFormState,
        id: newFormId,
      },
    ]);
  };

  const handleInputChange = (formIndex, questionIndex, type, value) => {
    setForms((prevForms) => {
      const updatedForms = [...prevForms];
      if (type === "notes") {
        updatedForms[formIndex].notes = value;
      } else {
        updatedForms[formIndex].questions[questionIndex] = {
          ...updatedForms[formIndex].questions[questionIndex],
          [type]: value,
        };
      }
      return updatedForms;
    });
  };

  const handleSubmit = async () => {
    const formData = forms.map((form) => ({
      id: form.id,
      answers: form.questions.map((q) => ({
        question: q.question,
        answer: q.answer,
        checked: q.checked,
      })),
      notes: form.notes,
    }));

    try {
      const response = await api.post("/review/createReview", {
        formData,
      });

      if (!response.ok) throw new Error("Network response was not ok");
      // const result = await response.json();

      setForms([initialFormState]);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowY: "auto" }}>
        <section>
          <div className="main-container">
            <h1 className="review-name">REVIEWS</h1>
          </div>
          {forms.map((form, formIndex) => (
            <div className="main-new-container" key={form.id}>
              <div className="review-form-container">
                <div className="container">
                  <p className="text-with-underline">
                    {formIndex === 0 ? (
                      <span className="circle plus" onClick={addNewForm}></span>
                    ) : (
                      <span className="icons"> </span>
                    )}
                    <span className="date">{formattedDate}</span>
                    <span className="review-items">REVIEW ITEMS</span>
                  </p>
                </div>
                <div style={{marginTop:'10px'}}>
                  {form.questions.map((q, index) => (
                    <div className="form-item" key={index}>
                      <p className="line-with-text">
                        <span className="left-text">{q.question}</span>
                        <span className="right-inputs">
                          <input
                            type="checkbox"
                            className="customs-checkbox"
                            id={`question-${form.id}-${index + 1}`}
                            name={`question-${form.id}-${index + 1}`}
                            checked={q.checked}
                            onChange={(e) =>
                              handleInputChange(
                                formIndex,
                                index,
                                "checked",
                                e.target.checked
                              )
                            }
                          />
                          <input
                            type="text"
                            onInput={(e) =>
                              (e.target.value = e.target.value.replace(
                                /[^A-Za-z]/g,
                                ""
                              ))
                            }
                            id={`details-${form.id}-${index + 1}`}
                            name={`details-${form.id}-${index + 1}`}
                            placeholder="Notes"
                            value={q.answer}
                            onChange={(e) =>
                              handleInputChange(
                                formIndex,
                                index,
                                "answer",
                                e.target.value
                              )
                            }
                          />
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="full-width-container">
                <textarea
                  type="text"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^A-Za-z]/g, ""))
                  }
                  className="full-width-input"
                  placeholder="Notes"
                  value={form.notes}
                  onChange={(e) =>
                    handleInputChange(formIndex, null, "notes", e.target.value)
                  }
                />
              </div>
            </div>
          ))}
          <div>
            <button type="submit" className="btn" onClick={handleSubmit}>
              SUBMIT
            </button>
          </div>
        </section>
      </Box>
    </Box>
  );
};

export default Reviews;
