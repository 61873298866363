import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import Header from "../HeadingList/HeadingList"; // Adjust the path as needed
// import DatePicker from "react-datepicker";
import "./FormDesign.css";
// import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { InputAdornment } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { format, isValid } from "date-fns";

const ClientInfo = ({ onDataChange, initialData }) => {
  const [formData, setFormData] = useState({
    personal: {
      firstName: "",
      lastName: "",
      birthDay: "",
    },
    spouse: {
      firstName: "",
      lastName: "",
      birthDay: "",
    },
    location: {
      address: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  const [open, setOpen] = useState(false); // State to manage DatePicker open status

  const handleDateChange = (newDate) => {
    // Format the date and update the formData
    const formattedDate =
      newDate && isValid(newDate) ? format(newDate, "MM/dd/yyyy") : "";
    setFormData((prevData) => ({
      ...prevData,
      personal: {
        ...prevData.personal,
        birthDay: formattedDate,
      },
    }));
  };

  // Update formData when initialData changes
  useEffect(() => {
    if (initialData) {
      setFormData({
        personal: {
          firstName: initialData.personal?.firstName || "",
          lastName: initialData.personal?.lastName || "",
          birthDay: initialData.personal?.birthDay || "",
        },
        spouse: {
          firstName: initialData.spouse?.firstName || "",
          lastName: initialData.spouse?.lastName || "",
          birthDay: initialData.spouse?.birthDay || "",
        },
        location: {
          address: initialData.location?.address || "",
          city: initialData.location?.city || "",
          state: initialData.location?.state || "",
          zip: initialData.location?.zip || "",
        },
      });
    }
  }, [initialData]);

  // Call onDataChange when formData changes
  useEffect(() => {
    onDataChange(formData);
  }, [formData, onDataChange]);

  // Handle changes in input fields
  const handleChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]:  value.length > 1 ? value.trimStart() : value,
      },
    }));
  };
  
  return (
    <>
      <Header name="CLIENT INFO" className="headerSpace" />
      <Box className="mt-1" sx={{ marginBottom: 6, padding: 2 }}>
        {/* Personal Information */}
        <Grid
          container
          spacing={2}
          className="gridHeight"
          alignItems="center"
          sx={{ mt: 1, flexWrap: "nowrap" }}
        >
          <Grid xs={12} sm={2}>
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              className="newClass"
            >
              <Typography variant="body1" className="list-1">
                Personal
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                placeholder="First Name"
                value={formData.personal.firstName}
                onChange={(e) => handleChange("personal", "firstName", e.target.value)}
                onBlur={(e) => handleChange("personal", "firstName", e.target.value.trim())} // Trim only on blur
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                onInput={(e) => {
                  // Trim leading spaces
                  let inputValue = e.target.value
                    .trimStart()
                    .replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                placeholder="Last Name"
                value={formData.personal.lastName}
                onChange={(e) =>
                  handleChange("personal", "lastName", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  open={open} // Controls the open state of the DatePicker
                  value={
                    formData.personal.birthDay
                      ? new Date(formData.personal.birthDay)
                      : null
                  }
                  onChange={(newDate) => {
                    const formattedDate =
                      newDate && isValid(newDate)
                        ? format(newDate, "MM/dd/yyyy")
                        : "";
                    handleChange("personal", "birthDay", formattedDate);
                    setOpen(false);
                  }}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select date"
                      InputLabelProps={{ shrink: false }}
                      label={null}
                      onClick={() => setOpen(true)}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}

                      sx={{
                        borderRadius: "0px",
                        width: "150px",
                        "& .MuiInputBase-root": {
                          borderRadius: "0px",
                          border: "1px solid #BFC0C2",
                          width: "150px",
                          fontSize: "15px",
                          cursor: "pointer",

                          "&:focus-within": {
                            border: "2px solid #BFC0C2", // Makes border transparent when focused
                          },
                        },
                        "& .MuiInputBase-input": {
                          // padding: "0px",
                          outline: "none", // Ensures no outline on input
                          cursor: "pointer",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Removes the border on focus
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarTodayIcon
                              style={{ fontSize: 15, cursor: "pointer" }}
                              onClick={() => setOpen(true)} // Open calendar on icon click
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </Grid>

        {/* Location Information */}
        <Grid
          container
          spacing={2}
          className="gridHeight"
          alignItems="center"
          sx={{ mt: 1, flexWrap: "nowrap" }}
        >
          <Grid xs={12} sm={2}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" className="list-1 locResp">
                Location
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item address-input">
              <input
                type="text"
                className="address-input"
                onInput={(e) => {
                  // Trim leading spaces
                  let inputValue = e.target.value.trimStart();
                  inputValue = inputValue.replace(
                    /[^A-Za-z0-9\s.,#\-&@()\/\\'";:!?%$*+=<>~`|]/g,
                    ""
                  );
                  if (inputValue) {
                    e.target.value =
                      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                  } else {
                    e.target.value = "";
                  }
                }}
                placeholder="Address"
                value={formData.location.address}
                onChange={(e) =>
                  handleChange("location", "address", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                onInput={(e) => {
                  // Trim leading spaces
                  let inputValue = e.target.value
                    .trimStart()
                    .replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                placeholder="City"
                value={formData.location.city}
                onChange={(e) =>
                  handleChange("location", "city", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="State"
                value={formData.location.state}
                onInput={(e) => {
                  let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                onChange={(e) =>
                  handleChange("location", "state", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                placeholder="Zip"
                maxLength={6}
                value={formData.location.zip}
                onChange={(e) =>
                  handleChange("location", "zip", e.target.value)
                }
              />
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default ClientInfo;
